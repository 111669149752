import React, { PropsWithChildren } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import Layout from '../components/layout/layout';
import { ButtonLink, H1, P } from '../components';
import { TitleImage } from '../components/images/beers';
import { RecipeItem } from '../types';
import Recipe from '../components/recipe/recipe';
import { useBeers } from '../hooks';

interface ContactProps {
  data: {
    allContentfulRecipe: {
      edges: RecipeItem[];
    };
  };
}

const RecipeRow: React.FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <div className="m-auto w-full flex flex-col lg:flex-row flex-wrap gap-5 justify-center items-center lg:items-start">
      {children}
    </div>
  );
};

export default function Contact({
  data: {
    allContentfulRecipe: { edges: recipes },
  },
}: ContactProps) {
  const beers = useBeers();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const filter = query.get('filter');
  const byCourse = filter === 'course';

  return (
    <Layout title="Food Pairing | Gastronomy and Cultural Pairings">
      <div className="relative w-full min-h-screen pt-32 md:pt-40 lg:pt-60 pb-20">
        <div className="w-full flex flex-col items-center">
          <div
            className="absolute inset-0 opacity-30"
            style={{ backgroundImage: `url("/images/index/background_forms.jpg")`, backgroundRepeat: 'repeat-y' }}
          />
          <H1 className="px-10 text-4xl font-medium override:text-primary z-20 lg:text-center">
            GASTRONOMY AND CULTURAL PAIRINGS
          </H1>
          <P className="px-10 font-medium text-xl leading-relaxed z-20">
            Our beers are a great match for food because of the complexity of their flavours, their ability to provide
            refreshment and to interact with various cuisines.
          </P>
          <P className="px-10 font-medium text-xl leading-relaxed z-20">
            When pairing beer and food, it all comes down to matching the foods flavours to the flavours of the beers.
            We bring you a carefully crafted selection of pairings to elevate your beer drinking experience.
          </P>
          <div className="px-10 my-10 flex mobile:flex-col lg:gap-10 gap-5 justify-center items-center">
            <ButtonLink
              to="/food-pairing?filter=course"
              overrideBtnClassName={`${byCourse ? 'override:bg-gray-400 cursor-default' : ''}`}
              main
            >
              By Course
            </ButtonLink>
            <ButtonLink
              to="/food-pairing?filter=beer"
              overrideBtnClassName={`${byCourse ? '' : 'override:bg-gray-400 cursor-default'}`}
              main
            >
              By Beer
            </ButtonLink>
          </div>
          <div className="w-full flex flex-col justify-center items-center text-center gap-20">
            {byCourse ? (
              <>
                <div className="w-full flex flex-col gap-5">
                  <H1 asH2>Entrees</H1>
                  <RecipeRow>
                    {recipes
                      .filter((recipe) => recipe.node.course === 'entree')
                      .map((recipe) => (
                        <Recipe
                          key={`${recipe.node.slug}-main`}
                          title={recipe.node.title}
                          slug={recipe.node.slug}
                          gatsbyImageData={recipe.node.image.gatsbyImageData}
                          imageAlt={recipe.node.image.title}
                        />
                      ))}
                  </RecipeRow>
                </div>
                <div className="w-full flex flex-col gap-5">
                  <H1 asH2>Mains</H1>
                  <RecipeRow>
                    {recipes
                      .filter((recipe) => recipe.node.course === 'main')
                      .map((recipe) => (
                        <Recipe
                          key={`${recipe.node.slug}-main`}
                          title={recipe.node.title}
                          slug={recipe.node.slug}
                          gatsbyImageData={recipe.node.image.gatsbyImageData}
                          imageAlt={recipe.node.image.title}
                        />
                      ))}
                  </RecipeRow>
                </div>
                <div className="w-full flex flex-col gap-5">
                  <H1 asH2>Desserts</H1>
                  <RecipeRow>
                    {recipes
                      .filter((recipe) => recipe.node.course === 'dessert')
                      .map((recipe) => (
                        <Recipe
                          key={`${recipe.node.slug}-main`}
                          title={recipe.node.title}
                          slug={recipe.node.slug}
                          gatsbyImageData={recipe.node.image.gatsbyImageData}
                          imageAlt={recipe.node.image.title}
                        />
                      ))}
                  </RecipeRow>
                </div>
              </>
            ) : (
              <>
                {beers.map((beer) => (
                  <div className="w-full flex-col gap-5" key={beer.slug}>
                    <div className="flex w-full justify-center items-center mb-5">
                      <TitleImage beer={beer} />
                    </div>
                    <RecipeRow>
                      {recipes
                        .filter((recipe) => recipe.node.pairedWith.find((b) => b.slug === beer.slug))
                        .map((recipe) => (
                          <Recipe
                            key={`${recipe.node.slug}-main`}
                            title={recipe.node.title}
                            slug={recipe.node.slug}
                            gatsbyImageData={recipe.node.image.gatsbyImageData}
                            imageAlt={recipe.node.image.title}
                          />
                        ))}
                    </RecipeRow>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    allContentfulRecipe(filter: { node_locale: { eq: $language } }) {
      edges {
        node {
          slug
          title
          pairedWith {
            slug
          }
          image {
            gatsbyImageData
            title
          }
          course
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
