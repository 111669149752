import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Link from '../link/link';

interface RecipeProps {
  slug: string;
  title: string;
  gatsbyImageData: IGatsbyImageData;
  imageAlt: string;
}

const Recipe: React.FC<RecipeProps> = ({ slug, title, gatsbyImageData, imageAlt }) => {
  return (
    <Link to={`/recipes/${slug}`} className="block transform hover:scale-105 w-60 h-60 md:w-96 md:h-96">
      <div className="w-full relative">
        <div className="absolute inset-0 bg-black opacity-0 transform hover:bg-opacity-30 hover:opacity-100 z-20 flex flex-col items-center justify-center">
          <p className="text-white text-xl font-semibold">{title}</p>
        </div>
        <GatsbyImage image={gatsbyImageData} alt={imageAlt} className="w-60 h-60 md:w-96 md:h-96" />
      </div>
    </Link>
  );
};

export default Recipe;
